import React from 'react';
import { shape, number, string } from 'prop-types';

import { Card, PolycardContext } from '@polycard/card';

const Polycard = ({ item, index, className }) => (
  <Card key={item?.metadata?.id} polycard={item} cardNumber={index} className={className} />
);

Polycard.propTypes = {
  className: string.isRequired,
  index: number.isRequired,
  item: shape({}).isRequired,
};

export { Polycard as Card, PolycardContext as Context };
